//import store from "@/support/mainstore";

const state = () => ({
    cardlist: {},  // set by init. to be used for multiple views/pages, using  "name:list" pairs    
    drawer: null,
})


const mutations = {
    setCardList(state, newcard) {
        state.cardlist[newcard.name] = newcard.data;
    }
}

const getters = {
    getCardList: (state) => (name) => {
        return { name: name, data: state.cardlist[name] }
    },
}

const actions = {
    initialize(context) {
        console.log("initializaing cards...")
        context.commit('setCardList', {
            name: "home",
            data: [                
                {
                    title: "Coral",
                    type: "coral",
                },
                {
                    title: "Relay",
                    type: "relays",
                },
                {
                    title: "Feedback",
                    type: "feedback",
                },
                {
                    title: "Events",
                    type: "events",
                },
            ]
        })
        context.commit('setCardList', {
            name: "control",
            data: [
                {
                    title: "Event Viewer",
                    type: "events",
                },
                {
                    title: "Actions",
                    type: "actions",
                },
            ]
        })
        console.log("initializaing cards done")
    }
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
