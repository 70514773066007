<template>
  <v-app-bar app color="primary" clipped-left dark>
    <v-icon @click="$router.push('config')">mdi-flash</v-icon>
    <span v-if="$vuetify.breakpoint.mdAndUp" class="mx-2"
      >MIT.nano Interlock</span
    >

    <v-divider vertical class="mx-2"></v-divider>
    {{ getSystemInfo }}
    <v-divider vertical class="mx-2"></v-divider>
    <v-btn class="mx-2" text fab small @click.stop="showHelp(getActiveData)">
      <v-icon>mdi-help-circle-outline</v-icon>
    </v-btn>

    <v-spacer></v-spacer>
    <v-btn text @click="$router.push('login')">
      <template v-if="$store.getters['user/isLoggedIn']">
        <span class="mr-2" v-if="$vuetify.breakpoint.mdAndUp">{{
          $store.getters["user/username"]
        }}</span>
        <v-icon>mdi-account</v-icon>
      </template>
      <template v-else>
        <span class="mr-2">LOGIN</span>
        <v-icon>mdi-account-off</v-icon>
      </template>
    </v-btn>
    <v-btn
      href="http://mitnano.mit.edu"
      target="_blank"
      text
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <span class="mr-2 text-capitalize">MIT.nano</span>
      <v-icon>mdi-open-in-new</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
//import dataloader from "@/api/dataloader.js";

export default {
  data: () => ({
    action: { loadingdata: false, canwrite: true, validuser: true },
    activespaceid: null,
    selectedOpen: false,
  }),
  asyncComputed: {
    config: {
      async get() {
        // get the setup data
        //var config = await dataloader.getInfo();
        console.log("STORE");
        const infodata = await this.$store.getters["data/getDataList"]("info");
        if (infodata.data === undefined) {
          console.log("Data not yet available");
          return null;
        }
        if (infodata.data.error) {
          console.log("Error loading data");
          return null;
        }
        console.log("STORE OK");
        return infodata.data;
      },
      default: [],
    },
    getSystemInfo: {
      async get() {
        console.log("Get SI");
        const configData = await this.$store.getters["data/getDataList"](
          "info"
        );
        console.log("SI: "+JSON.stringify(configData));
        if (configData.data === undefined) return "Interlock";
        return (
          configData.data.system.name +
          " located in " +
          configData.data.system.location
        );
      },
    },
  },
  computed: {
    getActiveData() {
      // fill in data about help here
      return { name: "Help Content", location: "4U", description: "Test" };
    },
  },
  methods: {
    async gotoNewLocation() {
      console.log("switch to somewhere");
    },

    async showHelp(item) {
      console.log("show some info");
      if (item == null) return;
      var aMessage = [];
      aMessage.push({ textbr: "Help Details", textclass: "font-weight-bold" });
      aMessage.push({ divider: true, textbr: "Name: " + item.name });
      aMessage.push({ textbr: "Location: " + item.location });
      aMessage.push({ textbr: " " });
      aMessage.push({ textbr: "Description", textclass: "font-weight-bold" });
      aMessage.push({ divider: true, textbr: item.description });
      aMessage.push({ textbr: " " });
      aMessage.push({
        textbr: "Booking Guidelines",
        textclass: "font-weight-bold",
      });
      aMessage.push({ divider: true });
      aMessage.push({ textbr: "No restrictions" });
      await this.$root.$confirmdialog(
        "Information for " + item.name,
        aMessage,
        { color: "secondary", reject: "", accept: "Okay" }
      );
    },
  },
};
</script>
