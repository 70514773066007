import Vue from 'vue';
import Vuex from 'vuex';

//import storemenu from '@/support/stores/storemenu';
import storeuser from '@/support/stores/storeuser';
import storecards from '@/support/stores/storecards';
import storedata from '@/support/stores/storedata';
import moment from 'moment';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        //menu: storemenu,
        user: storeuser,
        cards: storecards,
        data: storedata,
    },
    /*****************************************************************************
     * The store mostly has modules. A few states are on this
     * top level store, if they are generally useful for managing the entire page
     * such as data loading indicators.
     */
    state: {
        // start out as 'loading' as it get stopped once we completed initialization
        loadingdata: true,
        // expand contains the state of the chevrons for view cards (e.g. login etc)
        expand: {
            login: true,
            newuser: false,
        },
        visible: {
            myinfo: false,
        },
        refreshSeconds: 60,
    },
    getters: {
        isLoading: state => { return (state.loadingdata) },        
        isExpanded: state => (name) => {
            if (state.expand[name] === undefined) return true;
            return (state.expand[name]);
        },
        isVisible: state => (name) => {
            if (state.visible[name] === undefined) return true;
            return (state.visible[name]);
        },
    },

    mutations: {
        indicateLoading(state, value) {
            state.loadingdata = value;
        },
        toggleExpand(state, name) {
            if (state.expand[name] === undefined) state.expand[name] = false; // as we assume true if undefined
            state.expand[name] = !state.expand[name];
        },
        setVisible(state, name) {
            state.visible[name] = true;
        },
        setHidden(state, name) {
            state.visible[name] = false;
        },
    },
    // ***************************************************************************
    actions: {
        /**
         * onCreate is called in Vue at the start, to trigger any
         * initialization actions for each store module
         * Initializations are async, but we don't await because
         * the app can continue loading in parallel until completed
         * whoever calls onCreate can then await results
         */
        async onCreate() {                        
            console.log("--> Initializing States...");
            console.log("~ User");
            await this.dispatch('user/initialize')
            console.log("~ Cards");
            this.dispatch('cards/initialize')
            console.log("~ Data");
            await this.dispatch('data/initialize')
            console.log("Initializing States done");
            console.log("--> Launching the auto-refresh...");
            //var refreshSeconds = 10
            var self = this 
            setInterval(function () {
                console.log("reloading " + moment().format("HH:mm:ss"))
                try {
                    // refresh if not busy
                    self.dispatch("data/refreshAllIfNotBusy");
                } catch (ex) {
                    console.log("Error on Reload")
                    console.log(JSON.stringify(ex))
                }
                console.log("Done reloading "+moment().format("HH:mm:ss"))
            }, this.state.refreshSeconds * 1000);
        },
    },



});