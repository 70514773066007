/*********************************************
 * 
 *  All relevant code to connect to the API
 * 
 *  No other part of the code will access the http API. This way, all URLs are collected
 *  and abstracted out in this module.
 * 
 */

import http from "@/api/http-common";
//import { createNamespacedHelpers } from "vuex";
//var moment = require('moment');

var dataload = {};

dataload.routePageTo = function (newPage) {
    if (this.$router === undefined) {
        this.$router.push(newPage);
        return true;
    }
    if (this.$router.path !== newPage) {
        this.$router.push(newPage);
        return true;
    }
    return false;
}

dataload.login = async function (username, password) {
    console.log("login user");
    var requestParams = { username: username, password: password };
    dataload.username = username
    return (await http.post('/auth/login', requestParams)).data;
}
dataload.logout = async function () {
    console.log("logout user");
    dataload.username = undefined
    return (await http.get('/auth/logout')).data;
}
/*dataload.saveUserState = async function (configuration) {
    console.log("saving user config");
    var requestParams = { userdata: configuration };
    console.log("--> saving user state: "+JSON.stringify(requestParams))
    return (await http.post('/users/state', requestParams)).data;
}
*/
dataload.changePassword = async function (oldpass, newpass) {
    console.log("updating password");
    if (dataload.username === undefined) return { error: true, message: "Not logged in" }
    var requestParams = { username: dataload.username, oldpass: oldpass, newpass: newpass };
    return (await http.post('/auth/passwd', requestParams)).data;
}

/*****************
 * Specific functions of interlock API
 */

dataload.extractData = async function (sURL, defaultValue = []) {
    try {
        console.log("getting "+sURL)
        const content = await http.get(sURL)
        if (content === undefined) return defaultValue;
        if (content.data === undefined) return defaultValue;
        return content.data
    } catch (ex) {
        console.log("Error in getting data from " + sURL)
        console.log(ex)
    }
}

dataload.getConfig = async function () {
    console.log("get config");
    return (await dataload.extractData('/config'))
}
dataload.getState = async function () {
    console.log("get state");
    return (await dataload.extractData('/state'))
}
dataload.getInfo = async function () {
    console.log("get info");
    var contentInfo = (await dataload.extractData('/info'))
    console.log(JSON.stringify(contentInfo))
    return contentInfo
}
dataload.getData = async function () {
    console.log("get data");
    return (await dataload.extractData('/data'))
}
dataload.getEvents = async function () {
    console.log("get events");
    return (await dataload.extractData('/events/all'))
}
dataload.setRelay = async function (channel, newstate) {
    console.log("set " + channel + " to " + newstate);
    return (await http.get('/' + channel + "/" + newstate)).data;
}

dataload.engage = async function (channel) {
    console.log("engage " + String(channel));
    return (await http.get('/' + String(channel) + "/enable")).data;
}
dataload.disengage = async function (channel) {
    console.log("disengage " + String(channel));
    return (await http.get('/' + String(channel) + "/disable")).data;
}
dataload.restoreAll = async function () {
    console.log("restore all");
    return (await http.get('/restore')).data;
}

dataload.reboot = async function () {
    console.log("rebooting");
    return (await http.get('/reboot')).data;
}
export default dataload;

