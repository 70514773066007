<!-------------------------------------------------------------

MIT.nano Main app
This is just a skeleton to include
the structure with top and side bar
as well as main paged.

Main page is handled via router and parameters
while side bars and top bar react to $store values

-------------------------------------------------------------->

<template>
  <v-app>
    <!--- Core modal components --->
    <ConfirmDialog ref="confirmdialog"></ConfirmDialog>
    <!--- Main App --->
    <TopBar />    
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/navigation/TopBar";
import ConfirmDialog from "@/support/modals/ConfirmDialog";

export default {
  name: "App",
  components: {
    TopBar,    
    ConfirmDialog,
  },
  mounted() {
    console.log("mounting components...");
    this.$root.$confirmdialog = this.$refs.confirmdialog.open;
    console.log("mounting components done");
  },
};
</script>

<!--- make sure Z index for sidebar is sufficiently above content, but below the modal dialogs --->
<style>
.v-navigation-drawer {
  z-index: 100 !important;
}
</style>