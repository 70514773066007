import dataloader from "@/api/dataloader";

/*****************
 * 
 *   User configuration:  
 * 
 */

// default settings:
const user_base = {
    id: 0,
    name: "",
    email: "",
}
const config_base = {
    error: false,
}
const auth_base = {
    valid: false,
    isSiteAdmin: false,
    isUser: false,
}

// state contains user info & settings
const state = () => ({
    user: { ...user_base },
    configuration: { ...config_base },
    auth: { ...auth_base },
})


const getters = {
    username: state => {
        return state.user.username;
    },
    userid: state => {
        return state.user.id;
    },
    permissions: state => {
        return state.user.permissions;
    },
    isLoggedIn: state => {
        return state.auth.valid
    },
    userauth: state => {
        return state.auth
    }
}

const mutations = {
    configuration(state, confignew) {
        state.configuration = { ...config_base, ...confignew }
    },
    setUser(state, user) {
        if (user === undefined)
            return;
        if (user.id == 0) {
            console.log("Guest User Login")
            state.user = { id: 0, username: "guest", permissions: ["view"] }
            state.auth.valid = false 
        } else {
            console.log("User Login")
            state.user = JSON.parse(JSON.stringify(user));
            state.auth.valid = true
        }
        console.log("Set user = " + JSON.stringify(state.user))
    },
}

const actions = {
    async setAndSaveConfiguration(state, confignew) {
        await state.commit('configuration', confignew)
        //return await dataloader.saveUserState(confignew)
    },
    async initialize() {
        console.log("USER STORE = Initializing user")
        // load the "myself" user, to recover existing sessions. or, if not logged in, get the guest user
        //var result = await dataloader.getUser()
        //if (!result.error) {
          //  commit('setUser', result.data)
            //await this.dispatch('spaces/loadspaces')
       // }
    },
    async login({ commit }, params) {
        console.log("USER STORE = Logging in...")
        var result = await dataloader.login(params.username, params.password)
        
        if (result.username !== undefined) {
            commit('setUser', result)
            // successful login, also trigger a reload of the space list...
            //await this.dispatch('spaces/loadspaces')
            console.log("login done")
            console.log(JSON.stringify(result))
            return true;
        }
        console.log("login error")
        console.log(JSON.stringify(result))
        return false;
    },
    async logout({ state }) {
        console.log("USER STORE = Logging out...")
        // at logout, destroy auth settings and reload space list...
        await dataloader.logout()
        state.user = { ...user_base };
        state.config = { ...config_base };
        state.auth = { ...auth_base }
        //await this.dispatch('spaces/loadspaces')
    },
    //    async reminder(state, params) {
    //console.log("USER STORE = Sending reminder...")
    //return await dataloader.reminder(params.username)
    //},
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}