import Vue from 'vue'
import App from './App.vue'
import AsyncComputed from 'vue-async-computed'
import vuetify from './plugins/vuetify';

import router from './support/router';
import store from './support/mainstore';

Vue.config.productionTip = false
Vue.use(AsyncComputed)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  async created() {
      // once created, trigger the stores to pre-init any data
      console.log('initializing async...')
      await this.$store.dispatch('onCreate')     
      console.log('initializing done')
      this.$store.commit('indicateLoading',false)
  }
}).$mount('#app')
